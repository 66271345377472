import { useCallback, useMemo, useState } from "react";

const useCreateVisitorInputValidation = () => {
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicTouched, setSponsorAsicTouched] = useState(false);
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [idPhotoFront, setIDPhotoFront] = useState<string>("");
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idNumber, setIDNumber] = useState<string>("");
  const [idNumberOnTouched, setIdNumberOnTouched] = useState(false);
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [operationalNeedTouch, setOperationalNeedTouched] = useState(false);

  const sponsorAsicIsError = useMemo(
    () => sponsorAsicId === "" && sponsorAsicTouched,
    [sponsorAsicId, sponsorAsicTouched]
  );

  const idNumberIsError = useMemo(
    () => idNumber === "" && idNumberOnTouched,
    [idNumber, idNumberOnTouched]
  );

  const operationalNeedisError = useMemo(
    () => operationalNeed === "" && operationalNeedTouch,
    [operationalNeed, operationalNeedTouch]
  );

  const checkCurrentStep = useCallback(
    (currentStep: number) => {
      if (currentStep === 2) {
        setSponsorAsicTouched(true);
        return (
          sponsorAsicId !== "" &&
          sponsorName !== "" &&
          sponsorMobileNumber !== ""
        );
      } else if (currentStep === 3) {
        return idPhotoFront !== "";
      } else if (currentStep === 4) {
        return facePhoto !== "";
      } else if (currentStep === 5) {
        setIdNumberOnTouched(true);
        return idNumber !== "";
      } else if (currentStep === 6) {
        setOperationalNeedTouched(true);
        return operationalNeed !== "";
      }

      return true;
    },
    [
      sponsorAsicId,
      sponsorName,
      sponsorMobileNumber,
      idPhotoFront,
      facePhoto,
      idNumber,
      operationalNeed,
    ]
  );

  return {
    sponsorAsicId,
    setSponsorAsicId,
    sponsorName,
    setSponsorName,
    sponsorMobileNumber,
    setSponsorMobileNumber,
    idPhotoFront,
    setIDPhotoFront,
    facePhoto,
    setFacePhoto,
    idNumber,
    setIDNumber,
    operationalNeed,
    setOperationalNeed,
    checkCurrentStep,
    //
    sponsorAsicIsError,
    idNumberIsError,
    operationalNeedisError,
  };
};

export default useCreateVisitorInputValidation;
