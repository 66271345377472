import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  CogIcon,
  HomeIcon,
  MailIcon,
  SaveIcon,
  TicketIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { NavBar } from "../../components/NavBar/NavBar";
import PhotoModal from "../../components/PhotoModal/PhotoModal";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { classNames } from "../../utils/helpers/misc";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "orange",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "orange",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "orange",
            outline: "none !important",
          },
          "&.MuiInputBase-input": {
            "&:not(.Mui-focused)": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "orange",
              },
            },
            "&:-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 100px white inset",
              "-webkit-text-fill-color": "black",
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:after": {
            borderBottomColor: "orange",
          },
          "&:before": {
            borderBottomColor: "orange",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "orange",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "orange",
          "&.Mui-focused": {
            color: "orange",
          },
        },
      },
    },
  },
});

export const VisitorPassDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuth();
  const [openDeleteVisitorPassModal, setOpenDeleteVisitorPassModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [sponsorResendSuccess, setSponsorResendSuccess] =
    useState<boolean>(false);
  const [sponsorChangedSuccess, setSponsorChangedSuccess] =
    useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [openFacePhotoModal, setOpenFacePhotoModal] = useState<boolean>(false);
  const [openIdFrontPhotoModal, setOpenIDFrontPhotoModal] =
    useState<boolean>(false);
  const [visitorPassId, setVisitorPassId] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idPhotoFront, setIDPhotoFront] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] =
    useState<string>("1");
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [sponsorEmail, setSponsorEmail] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [initialSponsorEmail, setInitialSponsorEmail] = useState<string>("");
  const [initialSponsorMobileNumber, setInitialSponsorMobileNumber] =
    useState<string>("");
  const [sponsorStatus, setSponsorStatus] = useState<number>(0);
  const [idType, setIDType] = useState<string>("Driver's License");
  const [idNumber, setIDNumber] = useState<string>("");
  const [airport, setAirport] = useState<any>();
  const [rangeType, setRangeType] = useState<string>("day");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [cancelReason, setCancelReason] = useState<string>("");

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const goToProfile = () => {
    navigate("/settings");
  };

  const navigation = [
    {
      name: "Home",
      onClick: goToDashboard,
      icon: HomeIcon,
      current: true,
    },
    { name: "Profile", onClick: goToProfile, icon: CogIcon, current: false },
  ];

  useEffect(() => {
    if (auth.user && !auth.loading) {
      setLoading(true);
      api.updateClient(auth.token);
      fetchVisitorPass();
    }
  }, [auth.loading]);

  const fetchVisitorPass = async () => {
    try {
      const visitorPassResponse = await api.getVisitorPassById(
        params.visitorPassId
      );
      if (visitorPassResponse.status === 200 && visitorPassResponse.data) {
        const visitorPass = visitorPassResponse.data;
        setVisitorPassId(visitorPass.id ?? "");
        setFirstName(visitorPass.firstName ?? "");
        setLastName(visitorPass.lastName ?? "");
        setCompany(visitorPass.company ?? "");
        setMobileNumber(visitorPass.mobileNumber ?? "");
        setEmail(visitorPass.email ?? "");
        setOperationalNeed(visitorPass.operationalNeed ?? "");
        setSponsorName(visitorPass.sponsorName ?? "");
        setSponsorAsicId(visitorPass.sponsorAsicId ?? "");
        setSponsorAsicExpiryMonth(visitorPass.sponsorAsicExpiryMonth ?? "");
        setSponsorAsicExpiryYear(visitorPass.sponsorAsicExpiryYear ?? "");
        setSponsorCompany(visitorPass.sponsorCompany ?? "");
        setInitialSponsorEmail(visitorPass.sponsorEmail ?? "");
        setInitialSponsorMobileNumber(visitorPass.sponsorMobileNumber ?? "");
        setSponsorEmail(visitorPass.sponsorEmail ?? "");
        setSponsorMobileNumber(visitorPass.sponsorMobileNumber ?? "");
        setSponsorStatus(visitorPass.sponsorStatus);
        setIDType(visitorPass.idType ?? "");
        setIDNumber(visitorPass.idNumber ?? "");
        setRangeType(visitorPass.rangeType ?? "");
        setStartTime(visitorPass.startTime.substring(0, 16) ?? "");
        setEndTime(visitorPass.endTime.substring(0, 16) ?? "");
        setCreatedAt(visitorPass.createdAt ?? "");
        setUpdatedAt(visitorPass.updatedAt ?? "");
        setAirport(visitorPass.airport);
        setStatus(visitorPass.status ?? "Unknown");
        setRejectReason(visitorPass.rejectReason ?? "");
        setCancelReason(visitorPass.cancelReason ?? "");

        setLoading(false);

        if (visitorPass.facePhoto) {
          api
            .getVisitorPassFileByPath(visitorPass.id, visitorPass.facePhoto)
            .then((response) => {
              setFacePhoto(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }

        if (visitorPass.idPhotoFront) {
          api
            .getVisitorPassFileByPath(visitorPass.id, visitorPass.idPhotoFront)
            .then((response) => {
              setIDPhotoFront(response.data.url);
            })
            .catch((error) => {
              console.error("Error in getVisitorPassFileByPath - ", error);
            });
        }
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching visitor pass request with id ${params.visitorPassId}`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInvalidForm(
      !facePhoto ||
        !idPhotoFront ||
        !firstName ||
        !lastName ||
        !email ||
        !mobileNumber ||
        !company ||
        !operationalNeed ||
        !sponsorName ||
        !sponsorAsicId ||
        !sponsorAsicExpiryMonth ||
        !sponsorAsicExpiryYear ||
        !sponsorCompany ||
        (!sponsorMobileNumber && !sponsorEmail) ||
        !idType ||
        !idNumber ||
        !rangeType ||
        !startTime ||
        !endTime
    );
  }, [
    facePhoto,
    idPhotoFront,
    firstName,
    lastName,
    email,
    mobileNumber,
    company,
    operationalNeed,
    sponsorName,
    sponsorAsicId,
    sponsorAsicExpiryMonth,
    sponsorAsicExpiryYear,
    sponsorCompany,
    sponsorEmail,
    sponsorMobileNumber,
    idType,
    idNumber,
    rangeType,
    startTime,
    endTime,
  ]);

  const updateVisitorPass = async () => {
    setLoading(true);
    try {
      await api.updateVisitorPass(params.visitorPassId, {
        firstName,
        lastName,
        email,
        mobileNumber,
        company,
        facePhoto,
        idPhotoFront,
        idType,
        idNumber,
        sponsorAsicId,
        sponsorName,
        sponsorAsicExpiryMonth,
        sponsorAsicExpiryYear,
        sponsorCompany,
        sponsorEmail,
        sponsorMobileNumber,
        operationalNeed,
        rangeType,
        startTime,
        endTime,
      });

      if (
        sponsorEmail !== initialSponsorEmail ||
        sponsorMobileNumber !== initialSponsorMobileNumber
      ) {
        setSponsorChangedSuccess(true);
      }
      setSuccess(true);
      fetchVisitorPass();
    } catch (error) {
      console.error("Error occurred while updating visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const resendSponsorVerificationRequest = async () => {
    setLoading(true);
    try {
      await api.resendSponsorVerificationRequest(params.visitorPassId!);
      setSponsorResendSuccess(true);
    } catch (error) {
      console.error(
        "Error occurred while resending sponsor verification request - ",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteVisitorPass = async () => {
    setLoading(true);
    try {
      await api.deleteVisitorPassById(params.visitorPassId);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error occurred while deleting visitor pass - ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="min-h-full">
        <NavBar />
        <div className="py-4 sm:py-10">
          <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
              <nav
                aria-label="Sidebar"
                className="sticky top-4 divide-y divide-gray-300"
              >
                <div className="pb-8 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={item.onClick}
                      className={classNames(
                        item.current
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50",
                        "cursor-pointer group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </div>
              </nav>
            </div>
            <main className="lg:col-span-12 xl:col-span-10">
              <h1 className="text-2xl font-semibold px-4 sm:px-0 mb-4 hidden sm:flex flex-row items-center">
                <TicketIcon className="h-7 w-auto mr-1.5" />
                Visitor Pass Details
              </h1>
              <div className="mt-4 sm:px-0 px-4 max-w-screen-sm text-sm">
                <div className="mt-4 sm:px-0 px-4 max-w-screen-sm text-sm">
                  <div className="text-center font-semibold text-lg underline mb-4">
                    Request Details
                  </div>

                  <div className="text-center">
                    <span className="font-semibold mr-1.5">Created:</span>
                    {createdAt && (
                      <span>{new Date(createdAt).toLocaleString("en-AU")}</span>
                    )}
                  </div>
                  <div className="text-center">
                    <span className="font-semibold mr-1.5">Last updated:</span>
                    {updatedAt && (
                      <span>{new Date(updatedAt).toLocaleString("en-AU")}</span>
                    )}
                  </div>
                  <div className="text-center">
                    <span className="font-semibold mr-1.5">Airport:</span>
                    {airport
                      ? `${airport.name} (${airport.timezone})`
                      : "No Airport Specified"}
                  </div>
                  <div className="text-center">
                    <span className="font-semibold mr-1.5">Status:</span>
                    {status === 1 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                        Requested
                      </span>
                    )}
                    {status === 2 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Approved
                      </span>
                    )}
                    {status === 3 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Rejected
                      </span>
                    )}
                    {status === 4 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Expired
                      </span>
                    )}
                    {status === 5 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                        Cancelled
                      </span>
                    )}
                    {status === 6 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Printed
                      </span>
                    )}
                    {status === 0 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Unknown
                      </span>
                    )}
                  </div>
                  <div className="text-center">
                    <span className="font-semibold mr-1.5">
                      Sponsor Status:
                    </span>
                    {sponsorStatus === 1 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                        Pending
                      </span>
                    )}
                    {sponsorStatus === 2 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-200 text-green-900">
                        Approved
                      </span>
                    )}
                    {sponsorStatus === 3 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Declined
                      </span>
                    )}
                    {sponsorStatus === 0 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Unknown
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:px-0 px-4 max-w-screen-sm">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <label className="inline-block mb-2 text-gray-500">
                      Face Photo{showAsteriskIfEmpty(facePhoto)}
                    </label>
                    <div className="flex items-center justify-center w-full">
                      <label className="face-photo-label flex flex-col w-full h-fit hover:bg-gray-100 rounded-lg cursor-pointer">
                        {facePhoto ? (
                          <div className="flex flex-col items-center justify-center">
                            <img
                              style={{
                                maxHeight: "40vh",
                                width: "auto",
                                margin: "0 auto",
                              }}
                              className="rounded-md"
                              src={facePhoto}
                              alt="Face Photo"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col items-center justify-center py-7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              Loading image
                            </p>
                          </div>
                        )}
                        <input
                          className="opacity-0 hidden"
                          disabled={loading || (status !== 1 && status !== 3)}
                          onClick={(e) => setOpenFacePhotoModal(true)}
                        />
                        <PhotoModal
                          action={(imageSrc: string) => setFacePhoto(imageSrc)}
                          title={"Face Photo"}
                          description={
                            "Make sure your face is inside the frame"
                          }
                          open={openFacePhotoModal}
                          setOpen={setOpenFacePhotoModal}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <label className="inline-block mb-2 text-gray-500">
                      ID Front{showAsteriskIfEmpty(idPhotoFront)}
                    </label>
                    <div className="flex items-center justify-center w-full">
                      <label className="flex flex-col w-full h-fit hover:bg-gray-100 rounded-lg cursor-pointer">
                        {idPhotoFront ? (
                          <div className="flex flex-col items-center justify-center">
                            <img
                              style={{
                                maxHeight: "40vh",
                                width: "auto",
                                margin: "0 auto",
                              }}
                              className="rounded-md"
                              src={idPhotoFront}
                              alt="ID Front Photo"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col items-center justify-center py-7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              Loading image
                            </p>
                          </div>
                        )}
                        <input
                          className="opacity-0 hidden"
                          disabled={loading || (status !== 1 && status !== 3)}
                          onClick={(e) => setOpenIDFrontPhotoModal(true)}
                        />
                        <PhotoModal
                          action={(imageSrc) => setIDPhotoFront(imageSrc)}
                          title={"ID Front Photo"}
                          description={
                            "Make sure your document is inside the frame"
                          }
                          open={openIdFrontPhotoModal}
                          setOpen={setOpenIDFrontPhotoModal}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="first-name"
                        className="block text-xs font-medium text-gray-900"
                      >
                        First Name{showAsteriskIfEmpty(firstName)}
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="last-name"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Last Name{showAsteriskIfEmpty(lastName)}
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="email"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Email Address{showAsteriskIfEmpty(email)}
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="mobile-number"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Mobile Number{showAsteriskIfEmpty(mobileNumber)}
                      </label>
                      <input
                        type="text"
                        name="mobile-number"
                        id="mobile-number"
                        autoComplete="new-mobile-number"
                        required
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="company"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Company{showAsteriskIfEmpty(company)}
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="new-company"
                        required
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="operational-need"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Operational Need{showAsteriskIfEmpty(operationalNeed)}
                      </label>
                      <input
                        type="text"
                        name="operational-need"
                        id="operational-need"
                        autoComplete="new-operational-need"
                        required
                        value={operationalNeed}
                        onChange={(e) => setOperationalNeed(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="id-type"
                        className="block text-xs font-medium text-gray-900"
                      >
                        ID Type{showAsteriskIfEmpty(idType)}
                      </label>
                      <select
                        name="id-type"
                        id="id-type"
                        required
                        value={idType}
                        onChange={(e) => setIDType(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      >
                        <option value="Driver's License">
                          Driver's License
                        </option>
                        <option value="Passport">Passport</option>
                        <option value="National ID">National ID</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="id-number"
                        className="block text-xs font-medium text-gray-900"
                      >
                        ID Number{showAsteriskIfEmpty(idNumber)}
                      </label>
                      <input
                        type="text"
                        name="id-number"
                        id="id-number"
                        autoComplete="new-id-number"
                        required
                        value={idNumber}
                        onChange={(e) => setIDNumber(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="pass-duration"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Pass Duration
                      </label>
                      <select
                        id="pass-duration"
                        name="pass-duration"
                        required
                        value={rangeType}
                        onChange={(e) => setRangeType(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={true}
                      >
                        <option value="24 Hours">24 Hours</option>
                        <option value="1 Week">1 Week</option>
                        <option value="1 Month">1 Month</option>
                        <option value="Custom">Custom</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="start-time"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Start Time
                      </label>
                      <input
                        type="datetime-local"
                        id="start-time"
                        name="start-time"
                        required
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                        step="900"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="end-time"
                        className="block text-xs font-medium text-gray-900"
                      >
                        End Time
                      </label>
                      <input
                        type="datetime-local"
                        id="end-time"
                        name="end-time"
                        required
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-6 text-center font-semibold text-lg underline">
                    Sponsor Details
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-name"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor Name
                      </label>
                      <input
                        type="text"
                        id="sponsor-name"
                        name="sponsor-name"
                        required
                        value={sponsorName}
                        onChange={(e) => setSponsorName(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-asic-id"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor ASIC ID
                      </label>
                      <input
                        type="text"
                        id="sponsor-asic-id"
                        name="sponsor-asic-id"
                        required
                        value={sponsorAsicId}
                        onChange={(e) => setSponsorAsicId(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-asic-expiry-month"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor ASIC Expiry Month
                      </label>
                      <select
                        id="sponsor-asic-expiry-month"
                        name="sponsor-asic-expiry-month"
                        required
                        value={sponsorAsicExpiryMonth}
                        onChange={(e) =>
                          setSponsorAsicExpiryMonth(e.target.value)
                        }
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      >
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">Mar</option>
                        <option value="4">Apr</option>
                        <option value="5">May</option>
                        <option value="6">Jun</option>
                        <option value="7">Jul</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-asic-expiry-year"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor ASIC Expiry Year
                      </label>
                      <select
                        id="sponsor-asic-expiry-year"
                        name="sponsor-asic-expiry-year"
                        required
                        value={sponsorAsicExpiryYear}
                        onChange={(e) =>
                          setSponsorAsicExpiryYear(e.target.value)
                        }
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        disabled={loading || (status !== 1 && status !== 3)}
                      >
                        <option value={new Date().getFullYear()}>
                          {new Date().getFullYear()}
                        </option>
                        <option value={new Date().getFullYear() + 1}>
                          {new Date().getFullYear() + 1}
                        </option>
                        <option value={new Date().getFullYear() + 2}>
                          {new Date().getFullYear() + 2}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-company"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor Company{showAsteriskIfEmpty(sponsorCompany)}
                      </label>
                      <input
                        type="text"
                        id="sponsor-company"
                        name="sponsor-company"
                        required
                        value={sponsorCompany}
                        onChange={(e) => setSponsorCompany(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        autoComplete="new-sponsor-company"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                      <label
                        htmlFor="sponsor-email"
                        className="block text-xs font-medium text-gray-900"
                      >
                        Sponsor Email
                        {showAsteriskIfEmpty(
                          !sponsorEmail && !sponsorMobileNumber
                            ? ""
                            : "non-empty"
                        )}
                      </label>
                      <input
                        type="email"
                        id="sponsor-email"
                        name="sponsor-email"
                        required={!sponsorEmail && !sponsorMobileNumber}
                        value={sponsorEmail}
                        onChange={(e) => setSponsorEmail(e.target.value)}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                        autoComplete="new-sponsor-email"
                        disabled={loading || (status !== 1 && status !== 3)}
                      />
                    </div>
                  </div>

                  {rejectReason && (
                    <div className="col-span-6">
                      <label
                        htmlFor="reject-reason"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Reject Reason
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="reject-reason"
                          name="reject-reason"
                          disabled
                          value={rejectReason}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                        />
                      </div>
                    </div>
                  )}

                  {cancelReason && (
                    <div className="col-span-6">
                      <label
                        htmlFor="cancel-reason"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cancel Reason
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="cancel-reason"
                          name="cancel-reason"
                          disabled
                          value={cancelReason}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-span-6 flex w-full flex-col sm:flex-row space-y-1 sm:space-y-0">
                    {(status === 1 || status === 3) && (
                      <button
                        onClick={() => updateVisitorPass()}
                        disabled={loading || (status !== 1 && status !== 3)}
                        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50 sm:mr-2"
                      >
                        <SaveIcon className="h-5 w-5 mr-1.5" />
                        <span>Update</span>
                      </button>
                    )}
                    {status === 1 && (
                      <button
                        onClick={() => resendSponsorVerificationRequest()}
                        disabled={loading || status !== 1}
                        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 sm:mr-2"
                      >
                        <MailIcon className="h-5 w-5 mr-1.5" />
                        <span>Re-request Sponsor</span>
                      </button>
                    )}

                    {/* <button
                      onClick={() => setOpenDeleteVisitorPassModal(true)}
                      disabled={loading || status === 6}
                      className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                    >
                      <TrashIcon className="h-5 w-5 mr-1.5" />
                      <span>Delete</span>
                    </button> */}
                    <button
                      onClick={() => navigate("/dashboard")}
                      className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    </button>
                  </div>
                  <div className="col-span-6 sm:col-span-3 flex flex-row"></div>
                </div>
                {error && (
                  <div className="block text-sm font-medium text-red-500 mt-4">
                    <div className="my-1">{error}</div>
                  </div>
                )}
                {invalidForm && (
                  <div className="block text-sm font-medium text-red-500 mt-4">
                    <div className="my-1">
                      Please complete all required information (*) to update
                      your visitor pass request
                    </div>
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={success}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully updated visitor pass request
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please wait for it to be approved
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={sponsorResendSuccess}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully resent sponsor verification request
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please wait for your sponsor to verify and approve your
                        pass request
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setSponsorResendSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={sponsorChangedSuccess}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully changed pass sponsor
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please wait for your sponsor to verify and approve your
                        pass request
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setSponsorChangedSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={error}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <XIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        An error occurred
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please try again or contact support
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <DeleteModal
        description="Deleting a visitor pass is permanent. You will not be able to recover it once deleted"
        open={openDeleteVisitorPassModal}
        setOpen={setOpenDeleteVisitorPassModal}
        title={"Are you sure you want to delete this pass?"}
        action={() => deleteVisitorPass()}
      />
    </ThemeProvider>
  );
};
